<template>
    <div class="hospitals">
    <PreLoader v-if="isLoading"></PreLoader>
        <v-card>
          <v-card-title>
          </v-card-title>
        <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        item-key="id"
        >
          <template v-slot:top>
            <v-toolbar flat>
            <v-row>
              <v-col md="2" sm="2">
                <v-toolbar-title>Список тестов</v-toolbar-title>
              </v-col>
              <v-col md="3" sm="3">
                <v-select
                  v-model="l"
                  :items="ls"
                  label="Уровень теста"
                  item-value="name"
                  item-text="name"
                  required
                  solo
                  dense
                  @change="onLChange"
                  return-object
                ></v-select>
              </v-col>
              <!--<v-col md="3" sm="3">
                <v-select
                  v-model="t"
                  :items="ts"
                  label="Тип теста"
                  item-value="name"
                  item-text="name"
                  required
                  solo
                  dense
                  @change="onTChange"
                  return-object
                ></v-select>
              </v-col>-->
              <v-col md="3" sm="3">
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Поиск..."
                solo
                dense
                hide-details
                ></v-text-field>
              </v-col>
              <v-col md="3" sm="3" align="end">
                <v-btn color="#F53C56"  class="hospitals__addbtn mb-2 mr-5" @click="dialog=true">Добавить <v-icon>mdi-plus</v-icon></v-btn>
                <!--<v-btn color="#7764E4" class="hospitals__btn mb-2 mr-5">Экспорт</v-btn>-->
              </v-col>
            </v-row>
            </v-toolbar>
          </template>
          <template>
            
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon
              md
              class="mr-2"
              @click="editItem(item)"
              color="primary"
            >
              mdi-pencil-box-outline
            </v-icon>

            <v-dialog :retain-focus="false"
              v-model="dialog2"
              max-width="500"
            >
            <template v-slot:activator="{ on }">
              <v-icon
                md v-on="on"
                @click="dialog2 =true"
                color="#F53C56"
              >
                mdi-delete
              </v-icon>
            </template>
            
              <v-card>
                <v-card-title class="headline">
                  Вы действительно хотите удалить?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="dialog2=false"
                  >
                    Отменить
                  </v-btn>

                  <v-btn
                    color="green darken-1"
                    text
                    @click.stop="deleteHospital(item)"
                  >
                    Удалить
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
              
        </v-data-table>
        <v-dialog
                v-model="dialog"
                max-width="650px"
              >
                <v-card>
                  <v-card-title>
                    <span class="headline">Новый тест</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="4"
                          md="4"
                          style="padding-top:0px !important;padding-bottom:0px !important; paddin-left:10px;"
                        >
                          <label>Уровень теста</label>
                          <v-select
                            v-model="level"
                            :items="levels"
                            label="Уровень теста"
                            item-value="name"
                            item-text="name"
                            required
                            solo
                            dense
                            @change="onLevelChange"
                            return-object
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="4"
                          md="4"
                          style="padding-top:0px !important;padding-bottom:0px !important; paddin-left:10px;"
                        >
                          <label>Часть теста</label>
                          <v-select
                            v-model="part"
                            :items="parts"
                            item-value="name"
                            item-text="name"
                            label="Часть теста"
                            required
                            solo
                            dense
                            @change="onPartChange"
                            return-object
                          ></v-select>
                        </v-col>
                        <v-col
                          v-if="part.name !== 'VOCABULARY' && part.name !== 'LISTENING' && part.name !== 'GRAMMAR_VIDEOS'"
                          cols="12"
                          sm="4"
                          md="4"
                          style="padding-top:0px !important;padding-bottom:0px !important; paddin-left:10px;"
                        >
                          <label>Tип теста</label>
                          <v-select
                            v-model="type"
                            :items="types"
                            item-value="name"
                            item-text="name"
                            label="Тип теста"
                            required
                            solo
                            dense
                            @change="onPartChange"
                            return-object
                          ></v-select>
                        </v-col>
                        <!--BEGINNER-->
                          
                        <!--BEGINNER--END-->
                        <v-col
                          v-if="part.name !== 'LISTENING' || part.name !== 'GRAMMAR_VIDEOS' && part.name !== 'VOCABULARY'"
                          cols="12"
                          sm="6"
                          md="6"
                          style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                        >
                          <label>Название теста</label>
                          <v-text-field
                            v-model="test.title"
                            label="Название"
                            required
                            solo
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col
                          v-if="part.name === 'LISTENING' || part.name === 'GRAMMAR_VIDEOS'"
                          cols="12"
                          sm="6"
                          md="6"
                          style="padding-top:0px !important;padding-bottom:0px !important; paddin-left:10px;"
                        >
                          <label>Тема видео</label>
                          <v-text-field
                            v-model="test.title"
                            label="Тема Видео"
                            required
                            solo
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col
                          v-if="part.name === 'LISTENING' || part.name === 'GRAMMAR_VIDEOS'"
                          cols="12"
                          sm="6"
                          md="6"
                          style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                        >
                          <label>Код видео</label>
                          <v-text-field
                            v-model="test.code"
                            label="Код видео"
                            required
                            solo
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col
                          v-if="part.name === 'VOCABULARY'"
                          cols="12"
                          sm="12"
                          md="12"
                          style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                        >
                          <label>Тема </label>
                          <v-text-field
                            v-model="test.title"
                            label="Тема слов"
                            required
                            solo
                            dense
                          ></v-text-field>
                        </v-col>
                        <!--<v-col
                          v-if="type.name === 'VOCABULARY'"
                          cols="12"
                          sm="6"
                          md="6"
                          style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                        >
                          <label>Перевод слова</label>
                          <v-text-field
                            v-model="test.translation"
                            label="Перевод слова"
                            required
                            solo
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col
                          v-if="type.name === 'VOCABULARY'"
                          cols="12"
                          sm="6"
                          md="6"
                          style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                        >
                          <label>Пример</label>
                          <v-text-field
                            v-model="test.example"
                            label="Пример"
                            required
                            solo
                            dense
                          ></v-text-field>
                        </v-col>-->
                        
                        <v-col
                          v-if="part.name !== 'READING' && part.name !== 'LISTENING' && part.name !== 'GRAMMAR_VIDEOS' && part.name !== 'VOCABULARY' && type.name !== 'FILLING THE GAP'"
                          cols="12"
                          sm="6"
                          md="6"
                          style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                        >
                          <label>Вопрос теста</label>
                          <v-text-field
                            v-model="test.quest"
                            label="Вопрос текста"
                            solo
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col
                          v-if="part.name === 'READING'"
                          cols="12"
                          sm="6"
                          md="6"
                          style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                        >
                          <label>Загаловок текста</label>
                          <v-text-field
                            v-model="test.textTitle"
                            label="Загаловка текста"
                            solo
                            dense
                          ></v-text-field>
                        </v-col>
                        <!--BEGINNER-->
                          <v-col v-if="level.name ==='BEGINNER'" cols="12" md="12" sm="12" align="center">
                              <v-file-input
                                  :rules="rules"
                                  accept="image/png, image/jpeg, image/bmp"
                                  placeholder="Загрузите фото для теста"
                                  prepend-inner-icon="mdi-camera"
                                  solo
                                  @change="onChange"
                              ></v-file-input>
                          </v-col>
                        <!--BEGINNER--END-->
                        <!--<v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >

                          <v-text-field
                            v-model="test.quesOne"
                            label="Вопрос"
                            persistent-hint
                            required
                            solo
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                          <v-text-field
                            v-model="test.quesTwo"
                            label="Вопрос"
                            required
                            solo
                            dense
                          ></v-text-field>
                        </v-col>-->
                        <v-col
                          v-if="part.name === 'READING'"
                          cols="12" 
                          md="12" 
                          sm="12"
                          style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                        >
                          <label>Текст теста</label>
                          <v-textarea
                            v-model="test.text"
                            label="Текст теста"
                            required
                            solo
                            dense
                          ></v-textarea>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                          v-for="(question,index) in questionArr" :key="index"
                        >
                          <label>Вопрос {{ index += 1 }}</label>
                          <v-text-field
                            v-model="question.text"
                            label="Текст вопроса"
                            solo
                            dense
                          ></v-text-field>
                          <v-row v-for="(response,ik) in question.responses" :key="ik" style="margin-right:5px;">
                            <v-col cols="12" md="12" sm="12" align="center" style="padding:0px !important;">
                              <label>Вариант {{ ik += 1 }}</label>
                            </v-col>
                            <v-col cols="2" md="2" sm="2" style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;" >
                              <v-checkbox
                                v-model="response.isCorrect" 
                                hide-details
                                class="shrink mr-2 mt-1"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="10" md="10" sm="10" style="padding:0px !important;">
                              
                              <v-text-field
                                v-model="response.title"
                                label="Текст варианта"
                                solo
                                dense
                              ></v-text-field>
                            </v-col>
                            
                            
                          </v-row>
                          <v-btn color="orange" @click="addResponses(question)" solo><v-icon>mdi-plus-box</v-icon>Добавить варианты</v-btn>
                        </v-col>
                        <!--Vocabulary-->
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                          v-for="(word,index) in wordArr" :key="index"
                        >
                          <label>Слово {{ index += 1 }}</label>
                          <v-text-field
                            v-model="word.transcript"
                            label="Транскрипт"
                            solo
                            dense
                          ></v-text-field>
                          <v-text-field
                            v-model="word.translate"
                            label="Перевод"
                            solo
                            dense
                          ></v-text-field>
                          <v-text-field
                            v-model="word.example"
                            label="Пример"
                            solo
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12" sm="12" align="center" v-if="part.name === 'VOCABULARY'">
                            <v-btn color="orange" @click="addWords()" solo><v-icon>mdi-plus-box</v-icon>Добавить слово</v-btn>
                        </v-col>
                        <!---->
                        <!--Filling words-->
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                          v-for="(fill,index) in fillArr" :key="index"
                        >
                          <label>Вопрос {{ index += 1 }}</label>
                          <v-text-field
                            v-model="fill.quest"
                            label="Вопрос filling words"
                            solo
                            dense
                          ></v-text-field>
                          <v-text-field
                            v-model="fill.answer"
                            label="Ответ filling words"
                            solo
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12" sm="12" align="center" v-if="type.name === 'FILLING'">
                            <v-btn color="orange" @click="addFilling()" solo><v-icon>mdi-plus-box</v-icon>Добавить Filling words</v-btn>
                        </v-col>
                        <!---->
                        <v-col cols="12" md="12" sm="12" align="center" v-if="part.name !== 'LISTENING' && part.name !== 'GRAMMAR_VIDEOS' && part.name !== 'VOCABULARY' && type.name !== 'FILLING'">
                            <v-btn color="orange" @click="addQuestions()" solo><v-icon>mdi-plus-box</v-icon>Добавить вопросы</v-btn>
                        </v-col>
                        <!--<v-col
                          cols="12"
                          sm="6"
                          
                        >
                          <v-text-field
                            v-model="response.title"
                            label="Текст варианта"
                            solo
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12" sm="12" align="center">
                            <v-btn color="orange" @click="addResponses" solo><v-icon>mdi-plus-box</v-icon>Добавить варианты</v-btn>
                        </v-col>-->
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="dialog = false"
                    >
                      Отменить
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="AddTest"
                    >
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!--Edit Dialog-->
              <v-dialog
                v-model="editDialog"
                max-width="650px"
              >
                <v-card>
                  <v-card-title>
                    <span class="headline">Grammar Тест</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="4"
                          md="4"
                          style="padding-top:0px !important;padding-bottom:0px !important; paddin-left:10px;"
                        >
                          <label>Уровень теста</label>
                          <v-select
                            v-model="item.level"
                            :items="levels"
                            label="Уровень теста"
                            item-value="name"
                            item-text="name"
                            required
                            solo
                            dense
                            @change="onLevelChange"
                            return-object
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="4"
                          md="4"
                          style="padding-top:0px !important;padding-bottom:0px !important; paddin-left:10px;"
                        >
                          <label>Часть теста</label>
                          <v-select
                            v-model="item.part"
                            :items="parts"
                            item-value="name"
                            item-text="name"
                            label="Часть теста"
                            required
                            solo
                            dense
                            @change="onPartChange"
                            return-object
                          ></v-select>
                        </v-col>
                        <v-col
                        v-if="item.part ==='VOCABULARY'"
                          cols="12"
                          sm="4"
                          md="4"
                          style="padding-top:0px !important;padding-bottom:0px !important; paddin-left:10px;"
                        >
                          <label>Tип теста</label>
                          <v-select
                            v-model="item.type"
                            :items="types"
                            item-value="name"
                            item-text="name"
                            label="Тип теста"
                            required
                            solo
                            dense
                            @change="onPartChange"
                            return-object
                          ></v-select>
                        </v-col>
                        <v-col
                          v-if="item.part !== 'VIDEOS' && item.part !== 'VOCABULARY'"
                          cols="12"
                          sm="6"
                          md="6"
                          style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                        >
                          <label>Название теста</label>
                          <v-text-field
                            v-model="test.title"
                            label="Название"
                            required
                            solo
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!--Row Grammar-->
                        <v-row v-if="item.part === 'GRAMMAR'"> 
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                            v-for="(question,index) in item.questions" :key="index"
                          >
                            <label>Вопрос {{ index += 1 }}</label>
                            <v-text-field
                              v-model="question.text"
                              label="Текст вопроса"
                              solo
                              dense
                            ></v-text-field>
                            <v-row v-for="(response,ik) in question.responses" :key="ik" style="margin-right:5px;">
                              <v-col cols="12" md="12" sm="12" align="center" style="padding:0px !important;">
                                <label>Вариант {{ ik += 1 }}</label>
                              </v-col>
                              <v-col cols="2" md="2" sm="2" style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;" >
                                <v-checkbox
                                  v-model="response.isCorrect" 
                                  hide-details
                                  class="shrink mr-2 mt-1"
                                ></v-checkbox>
                              </v-col>
                              <v-col cols="10" md="10" sm="10" style="padding:0px !important;">
                                
                                <v-text-field
                                  v-model="response.title"
                                  label="Текст варианта"
                                  solo
                                  dense
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      <!--Row Grammar-->
                      <!--Row Vocabulary-->
                      <v-row v-if="item.part === 'VOCABULARY'">
                          <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                        >
                          <label>Тема </label>
                          <v-text-field
                            v-model="item.title"
                            label="Тема слов"
                            required
                            solo
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                          v-for="(word,index) in item.words" :key="index"
                        >
                          <label>Слово {{ index += 1 }}</label>
                          <v-text-field
                            v-model="word.transcript"
                            label="Транскрипт"
                            solo
                            dense
                          ></v-text-field>
                          <v-text-field
                            v-model="word.translate"
                            label="Перевод"
                            solo
                            dense
                          ></v-text-field>
                          <v-text-field
                            v-model="word.example"
                            label="Пример"
                            solo
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!--Row Vocabulary-->
                      <!--Row Videos-->
                      <v-row v-if="item.part === 'VIDEOS'">
                          <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          style="padding-top:0px !important;padding-bottom:0px !important; paddin-left:10px;"
                        >
                          <label>Тема видео</label>
                          <v-text-field
                            v-model="item.title"
                            label="Тема Видео"
                            required
                            solo
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                        >
                          <label>Код видео</label>
                          <v-text-field
                            v-model="test.code"
                            label="Код видео"
                            required
                            solo
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!--Row Videos-->
                      <!--Row Reading-->
                        <v-row v-if="item.part === 'READING'">
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                            style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                          >
                            <label>Загаловок текста</label>
                            <v-text-field
                              v-model="item.textTitle"
                              label="Загаловка текста"
                              solo
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col
                            v-if="item.part === 'READING'"
                            cols="12" 
                            md="12" 
                            sm="12"
                            style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                          >
                            <label>Текст теста</label>
                            <v-textarea
                              v-model="item.text"
                              label="Текст теста"
                              required
                              solo
                              dense
                            ></v-textarea>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                            v-for="(question,index) in item.questions" :key="index"
                          >
                            <label>Вопрос {{ index += 1 }}</label>
                            <v-text-field
                              v-model="question.text"
                              label="Текст вопроса"
                              solo
                              dense
                            ></v-text-field>
                            <v-row v-for="(response,ik) in question.responses" :key="ik" style="margin-right:5px;">
                              <v-col cols="12" md="12" sm="12" align="center" style="padding:0px !important;">
                                <label>Вариант {{ ik += 1 }}</label>
                              </v-col>
                              <v-col cols="2" md="2" sm="2" style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;" >
                                <v-checkbox
                                  v-model="response.isCorrect" 
                                  hide-details
                                  class="shrink mr-2 mt-1"
                                ></v-checkbox>
                              </v-col>
                              <v-col cols="10" md="10" sm="10" style="padding:0px !important;">
                                
                                <v-text-field
                                  v-model="response.title"
                                  label="Текст варианта"
                                  solo
                                  dense
                                ></v-text-field>
                              </v-col>
                              
                              
                            </v-row>
                          </v-col>
                        </v-row>
                      <!--Row Reading-->
                      <v-row v-if="item.type === 'FILLING'">
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          style="padding-top:0px !important;padding-bottom:0px !important; padding-left:10px;"
                          v-for="(fill,index) in fillArr" :key="index"
                        >
                          <label>Вопрос {{ index += 1 }}</label>
                          <v-text-field
                            v-model="fill.quest"
                            label="Вопрос filling words"
                            solo
                            dense
                          ></v-text-field>
                          <v-text-field
                            v-model="fill.answer"
                            label="Ответ filling words"
                            solo
                            dense
                          ></v-text-field>
                        </v-col>
                        
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="editDialog = false"
                    >
                      Отменить
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="AddTest"
                    >
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!--Edit Dialog-->
      </v-card>
  </div>
</template>
<script>
import PreLoader from '../../components/preloader/PreLoader.vue';
import * as firebase from '@/firebase';
export default {
    components: {
    PreLoader,
  },
  name: "Hospitals",
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'Название теста',
            align: 'start',
            value: 'title',
          },
          { text: 'Уровень', value: 'level' },
          { text: 'Част теста', value: 'part' },
          { text: 'Тип теста', value: 'type' },
          { text: '', value: 'action',sortable: false },
          
          
        ],
        close: false,
        dialog2:false,
        dialog:false,
        editDialog:false,
        locationId:0,
        isLoading:false,
        items:[],
        test : {
            id:'',
            textTitle: "",
            quesTwo: "",
            isFav: false,
            title: "",
            questions: [],
            text: "",
            quesOne: "",
            type: "",
        },
        responseArr:[],
        questionArr:[],
        wordArr: [],
        response: {},
        question: {
          title: 1,
          text: "",
          answer: '',
          responses: []
        },
        word:{
          translate:'',
          transcript:'',
          example:''
        },
        fillArr:[],
        fill:{
          quest:'',
          answer:''
        },
        num:0,
        levels: [
          {
            name: 'BEGINNER',
            beginnered: false
          },
          {
            name: 'ELEMENTARY',
            elementared: false
          },
          {
            name: 'PRE-INTERMEDIATE',
            preintermediated: false
          },
        ],
        ls: [
          {
            name: 'BEGINNER',
            beginnered: false
          },
          {
            name: 'ELEMENTARY',
            elementared: false
          },
          {
            name: 'PRE-INTERMEDIATE',
            preintermediated: false
          },
        ],
        l:{},
        parts: [
          {
            name: 'GRAMMAR',
            grammared:false
          },
          {
            name: 'GRAMMAR_VIDEOS',
            grammared:false
          },
          {
            name: 'VOCABULARY',
            vocabulared: false
          },
          {
            name: 'READING',
            readered: false
          },
          {
            name: 'LISTENING',
            videoded: false
          }
        ],
        types: [
          {
            name: 'MULTIPLE CHOICE',
          },
          {
            name: 'FILLING THE GAP',
          },
          {
            name: 'OPEN THE BRACKETS',
          },
          {
            name: 'TRUE/FALSE',
          },
          {
            name: 'TEST',
          }
        ],
        ts:[
          {
            name: 'GRAMMAR',
          },
          {
            name: 'VOCABULARY',
          },
          {
            name: 'READING',
          },
          {
            name: 'VIDEOS',
          }
        ],
        t:{},
        level: {},
        part:{},
        type:{},
        exersices: [
          {
            name: 'GRAMMAR',
            grammared:false
          },
          {
            name: 'VOCABULARY',
            vocabulared: false
          },
          {
            name: 'READING',
            readered: false
          },
          {
            name: 'VIDEOS',
            videoded: false
          }
        ],
        exersice: {},
        Arrs: [],
        item:{},
        fileUrl: ''
      }
    },
    created() {
      this.getBeginnerTests();
    },           
    methods: {
        async getTests() {
          this.items = [];
          this.isLoading = true;
          this.events = await firebase.bGrammarCollection.get()
            .then((snapshot) => {
              snapshot.forEach((el) => {
                  if(el.data().id){
                    this.items.push({...el.data()})
                  }
                  firebase.bReadingCollection.get()
                    .then((snapshot) => {
                      snapshot.forEach((el) => {
                        if(el.data().id){
                          this.items.push({...el.data()})
                        }
                        firebase.bVideosCollection.get()
                          .then((snapshot) => {
                            snapshot.forEach((el) => {
                              if(el.data().id){
                                this.items.push({...el.data()})
                              }
                              firebase.bVocabularyCollection.get()
                                .then((snapshot) => {
                                  snapshot.forEach((el) => {
                                    if(el.data().id){
                                      this.items.push({...el.data()})
                                    }
                                    firebase.eVocabularyCollection.get()
                                    .then((snapshot) => {
                                      snapshot.forEach((el) => {
                                        if(el.data().id){
                                          this.items.push({...el.data()})
                                        }
                                        firebase.eVideosCollection.get()
                                          .then((snapshot) => {
                                            snapshot.forEach((el) => {
                                              if(el.data().id){
                                                this.items.push({...el.data()})
                                              }
                                              firebase.eReadingCollection.get()
                                                .then((snapshot) => {
                                                  snapshot.forEach((el) => {
                                                    if(el.data().id){
                                                      this.items.push({...el.data()})
                                                    }
                                                    firebase.eGrammarCollection.get()
                                                      .then((snapshot) => {
                                                        snapshot.forEach((el) => {
                                                          if(el.data().id){
                                                            this.items.push({...el.data()})
                                                          }
                                                          firebase.pGrammarCollection.get()
                                                            .then((snapshot) => {
                                                              snapshot.forEach((el) => {
                                                                if(el.data().id){
                                                                  this.items.push({...el.data()})
                                                                }
                                                                firebase.pReadingCollection.get()
                                                                  .then((snapshot) => {
                                                                    snapshot.forEach((el) => {
                                                                      if(el.data().id){
                                                                        this.items.push({...el.data()})
                                                                      }
                                                                      firebase.pVocabularyCollection.get()
                                                                        .then((snapshot) => {
                                                                          snapshot.forEach((el) => {
                                                                            if(el.data().id){
                                                                              this.items.push({...el.data()})
                                                                            }
                                                                            firebase.pVideosCollection.get()
                                                                              .then((snapshot) => {
                                                                                snapshot.forEach((el) => {
                                                                                  if(el.data().id){
                                                                                    this.items.push({...el.data()})
                                                                                  }
                                                                                  this.items = this.items.filter((obj, index, selfArr) => index === selfArr.findIndex((el) => (el['id'] === obj['id'])));
                                                                                  this.isLoading = false;
                                                                                });
                                                                              });
                                                                          });
                                                                        });
                                                                    });
                                                                  });
                                                              });
                                                            });
                                                        });
                                                      });
                                                  });
                                                });
                                            });
                                          });
                                      });
                                    });
                                  });
                                });
                            });
                          });
                      });
                    });
              })
            })
        },
        async getBeginnerTests() {
          this.items = [];
          this.isLoading = true;
          this.events = await firebase.bGrammarCollection.get()
            .then((snapshot) => {
              snapshot.forEach((el) => {
                  if(el.data().id){
                    this.items.push({...el.data()})
                  }
                  firebase.bReadingCollection.get()
                    .then((snapshot) => {
                      snapshot.forEach((el) => {
                        if(el.data().id){
                          this.items.push({...el.data()})
                        }
                        firebase.bVideosCollection.get()
                          .then((snapshot) => {
                            snapshot.forEach((el) => {
                              if(el.data().id){
                                this.items.push({...el.data()})
                              }
                              firebase.bVocabularyCollection.get()
                                .then((snapshot) => {
                                  snapshot.forEach((el) => {
                                    if(el.data().id){
                                      this.items.push({...el.data()})
                                    }
                                    this.items = this.items.filter((obj, index, selfArr) => index === selfArr.findIndex((el) => (el['id'] === obj['id'])));
                                    this.isLoading = false;
                                    //this.getElemTests();
                                    //this.getPreTests();
                                  });
                                });
                            });
                          });
                      });
                    });
              });
            });
        },
        async getElemTests() {
          this.items = [];
          this.isLoading = true;
          await firebase.eVocabularyCollection.get()
            .then((snapshot) => {
              snapshot.forEach((el) => {
                if(el.data().id){
                  this.items.push({...el.data()})
                }
                firebase.eVideosCollection.get()
                  .then((snapshot) => {
                    snapshot.forEach((el) => {
                      if(el.data().id){
                        this.items.push({...el.data()})
                      }
                      firebase.eReadingCollection.get()
                        .then((snapshot) => {
                          snapshot.forEach((el) => {
                            if(el.data().id){
                              this.items.push({...el.data()})
                            }
                            firebase.eGrammarCollection.get()
                              .then((snapshot) => {
                                snapshot.forEach((el) => {
                                  if(el.data().id){
                                    this.items.push({...el.data()})
                                  }
                                  this.isLoading = false;
                                });
                              });
                          });
                        });
                    });
                  });
              });
            });
        },
        async getPreTests() {
          this.items = [];
          this.isLoading = true;
          await firebase.pGrammarCollection.get()
            .then((snapshot) => {
              snapshot.forEach((el) => {
                if(el.data().id){
                  this.items.push({...el.data()})
                }
                firebase.pReadingCollection.get()
                  .then((snapshot) => {
                    snapshot.forEach((el) => {
                      if(el.data().id){
                        this.items.push({...el.data()})
                      }
                      firebase.pVocabularyCollection.get()
                        .then((snapshot) => {
                          snapshot.forEach((el) => {
                            if(el.data().id){
                              this.items.push({...el.data()})
                            }
                            firebase.pVideosCollection.get()
                              .then((snapshot) => {
                                snapshot.forEach((el) => {
                                  if(el.data().id){
                                    this.items.push({...el.data()})
                                  }
                                  this.items = this.items.filter((obj, index, selfArr) => index === selfArr.findIndex((el) => (el['id'] === obj['id'])));
                                  this.isLoading = false;
                                });
                              });
                          });
                        });
                    });
                  });
              });
            });
        },
        async AddTest() {
          this.test.questions = this.questionArr;
          if(this.fileUrl) {
            this.test.avatarUrl = this.fileUrl;
          }
          if(this.level.name === 'BEGINNER'){
            console.log('BEGINNER!')
            if(this.level.name === 'BEGINNER' && this.part.name === 'GRAMMAR'){
              if(this.type.name ==='MULTIPLE CHOICE'){
                this.test.type = 'MULTIPLE CHOICE';
              }else if(this.type.name === 'FILLING THE GAP') {
                this.test.type = 'FILLING THE GAP';
              } else if (this.type.name === 'OPEN THE BRACKETS'){
                this.test.type = 'OPEN THE BRACKETS';
              } else if (this.type.name === 'TRUE/FALSE'){
                this.test.type = 'TRUE/FALSE';
              }else {
                this.test.type = 'TEST';
              }
              this.test.part = "GRAMMAR";
              this.test.level = "BEGINNER";
              await firebase.bGrammarCollection.add(this.test).then((snapshot) => {
                firebase.bGrammarCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.bGrammarCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Тест успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'BEGINNER' && this.part.name === 'READING') {
              if(this.type.name ==='MULTIPLE CHOICE'){
                this.test.type = 'MULTIPLE CHOICE';
              }else if(this.type.name === 'FILLING THE GAP') {
                this.test.type = 'FILLING THE GAP';
              } else if (this.type.name === 'OPEN THE BRACKETS'){
                this.test.type = 'OPEN THE BRACKETS';
              } else if (this.type.name === 'TRUE/FALSE'){
                this.test.type = 'TRUE/FALSE';
              }else {
                this.test.type = 'TEST';
              }
              this.test.part = "READING";
              this.test.level = "BEGINNER";
              await firebase.bReadingCollection.add(this.test).then((snapshot) => {
                firebase.bReadingCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.bReadingCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Тест успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'BEGINNER' && this.part.name === 'GRAMMAR_VIDEOS') {          
              this.test.part = "GRAMMAR_VIDEOS";
              this.test.level = "BEGINNER";
              await firebase.bVideosGrammarCollection.add(this.test).then((snapshot) => {
                firebase.bVideosGrammarCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.bVideosGrammarCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Видео успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'BEGINNER' && this.part.name === 'LISTENING') {
              this.test.part = "LISTENING";
              this.test.level = "BEGINNER";
              await firebase.bVideosCollection.add(this.test).then((snapshot) => {
                firebase.bVideosCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.bVideosCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Видео успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'BEGINNER' && this.part.name === 'VOCABULARY') {
              /*if(this.type.name ==='MULTIPLE CHOICE'){
                this.test.type = 'MULTIPLE CHOICE';
              }else if(this.type.name === 'FILLING THE GAP') {
                this.test.type = 'FILLING THE GAP';
              } else if (this.type.name === 'OPEN THE BRACKETS'){
                this.test.type = 'OPEN THE BRACKETS';
              } else if (this.type.name === 'TRUE/FALSE'){
                this.test.type = 'TRUE/FALSE';
              }else {
                this.test.type = 'TEST';
              }*/
              this.test.part = "VOCABULARY";
              this.test.level = "BEGINNER";
              this.test.words = this.wordArr;
              await firebase.bVocabularyCollection.add(this.test).then((snapshot) => {
                firebase.bVocabularyCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.bVocabularyCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Тест успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
          }
          else if(this.level.name === 'ELEMENTARY') {
            console.log('ELEMENTARY!')
            if(this.level.name === 'ELEMENTARY' && this.part.name === 'GRAMMAR'){
              if(this.type.name ==='MULTIPLE CHOICE'){
                this.test.type = 'MULTIPLE CHOICE';
              }else if(this.type.name === 'FILLING THE GAP') {
                this.test.type = 'FILLING THE GAP';
              } else if (this.type.name === 'OPEN THE BRACKETS'){
                this.test.type = 'OPEN THE BRACKETS';
              } else if (this.type.name === 'TRUE/FALSE'){
                this.test.type = 'TRUE/FALSE';
              }else {
                this.test.type = 'TEST';
              }
              this.test.part = "GRAMMAR";
              this.test.level = "ELEMENTARY";
              await firebase.eGrammarCollection.add(this.test).then((snapshot) => {
                firebase.eGrammarCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.eGrammarCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Тест успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'ELEMENTARY' && this.part.name === 'READING') {
              if(this.type.name ==='MULTIPLE CHOICE'){
                this.test.type = 'MULTIPLE CHOICE';
              }else if(this.type.name === 'FILLING THE GAP') {
                this.test.type = 'FILLING THE GAP';
              } else if (this.type.name === 'OPEN THE BRACKETS'){
                this.test.type = 'OPEN THE BRACKETS';
              } else if (this.type.name === 'TRUE/FALSE'){
                this.test.type = 'TRUE/FALSE';
              }else {
                this.test.type = 'TEST';
              }
              this.test.part = "READING";
              this.test.level = "ELEMENTARY";
              await firebase.eReadingCollection.add(this.test).then((snapshot) => {
                firebase.eReadingCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.eReadingCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Тест успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'ELEMENTARY' && this.part.name === 'GRAMMAR_VIDEOS') {
              this.test.part = "GRAMMAR_VIDEOS";
              this.test.level = "ELEMENTARY";
              await firebase.eVideosGrammarCollection.add(this.test).then((snapshot) => {
                firebase.eVideosGrammarCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.eVideosGrammarCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Видео успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'ELEMENTARY' && this.part.name === 'LISTENING') {
              this.test.part = "LISTENING";
              this.test.level = "ELEMENTARY";
              await firebase.eVideosCollection.add(this.test).then((snapshot) => {
                firebase.eVideosCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.eVideosCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Видео успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'ELEMENTARY' && this.part.name === 'VOCABULARY') {
              /*if(this.type.name ==='MULTIPLE CHOICE'){
                this.test.type = 'MULTIPLE CHOICE';
              }else if(this.type.name === 'FILLING THE GAP') {
                this.test.type = 'FILLING THE GAP';
              } else if (this.type.name === 'OPEN THE BRACKETS'){
                this.test.type = 'OPEN THE BRACKETS';
              } else if (this.type.name === 'TRUE/FALSE'){
                this.test.type = 'TRUE/FALSE';
              }else {
                this.test.type = 'TEST';
              }*/
              this.test.part = "VOCABULARY";
              this.test.level = "ELEMENTARY";
              this.test.words = this.wordArr;
              await firebase.eVocabularyCollection.add(this.test).then((snapshot) => {
                firebase.eVocabularyCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.eVocabularyCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Тест успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
          }
          else if(this.level.name === 'PRE-INTERMEDIATE') {
            console.log('PRE-INTERMEDIATE');
            if(this.level.name === 'PRE-INTERMEDIATE' && this.part.name === 'GRAMMAR'){
              if(this.type.name ==='MULTIPLE CHOICE'){
                this.test.type = 'MULTIPLE CHOICE';
              }else if(this.type.name === 'FILLING THE GAP') {
                this.test.type = 'FILLING THE GAP';
              } else if (this.type.name === 'OPEN THE BRACKETS'){
                this.test.type = 'OPEN THE BRACKETS';
              } else if (this.type.name === 'TRUE/FALSE'){
                this.test.type = 'TRUE/FALSE';
              }else {
                this.test.type = 'TEST';
              }
              this.test.part = "GRAMMAR";
              this.test.level = "PRE-INTERMEDIATE";
              await firebase.pGrammarCollection.add(this.test).then((snapshot) => {
                firebase.pGrammarCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.pGrammarCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Тест успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'PRE-INTERMEDIATE' && this.part.name === 'READING') {
              if(this.type.name ==='MULTIPLE CHOICE'){
                this.test.type = 'MULTIPLE CHOICE';
              }else if(this.type.name === 'FILLING THE GAP') {
                this.test.type = 'FILLING THE GAP';
              } else if (this.type.name === 'OPEN THE BRACKETS'){
                this.test.type = 'OPEN THE BRACKETS';
              } else if (this.type.name === 'TRUE/FALSE'){
                this.test.type = 'TRUE/FALSE';
              }else {
                this.test.type = 'TEST';
              }
              this.test.part = "READING";
              this.test.level = "PRE-INTERMEDIATE";
              await firebase.pReadingCollection.add(this.test).then((snapshot) => {
                firebase.pReadingCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.pReadingCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Тест успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'PRE-INTERMEDIATE' && this.part.name === 'GRAMMAR_VIDEOS') {
              this.test.part = "GRAMMAR_VIDEOS";
              this.test.level = "PRE-INTERMEDIATE";
              await firebase.pVideosGrammarCollection.add(this.test).then((snapshot) => {
                firebase.pVideosGrammarCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.pVideosGrammarCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Видео успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'PRE-INTERMEDIATE' && this.part.name === 'LISTENING') {
              this.test.part = "LISTENING";
              this.test.level = "PRE-INTERMEDIATE";
              await firebase.pVideosCollection.add(this.test).then((snapshot) => {
                firebase.pVideosCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.pVideosCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Видео успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'PRE-INTERMEDIATE' && this.part.name === 'VOCABULARY') {
              this.test.part = "VOCABULARY";
              this.test.level = "PRE-INTERMEDIATE";
              this.test.words = this.wordArr;
              await firebase.pVocabularyCollection.add(this.test).then((snapshot) => {
                firebase.pVocabularyCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.pVocabularyCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Тест успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
          }
        },

        /*async AddTest() {
          this.test.questions = this.questionArr;
          console.log(this.test)
          await firebase.testsCollection.add(this.test).then((snapshot) => {
              console.log(snapshot.id)
              firebase.testsCollection.doc(snapshot.id).get().then((snapshot) => {
                const data = snapshot.data();
                firebase.testsCollection.doc(snapshot.id).set({
                  ...data,
                  id:snapshot.id
                }).then(() => {
                  this.test = {};
                  console.log('Successfully added!')
                  
                })
              })
              this.dialog = false;
              this.items = [];
              this.getTests();
              //return (snapshot.data())  
          }).catch(err=>{console.log(err)});
        },*/
        deleteHospital (item) {
          if(item.level === 'BEGINNER'){
            switch (item.part) {
              case 'GRAMMAR': 
                firebase.bGrammarCollection.doc(item.id).delete().then(() => {
                    this.dialog2 = false;
                    this.$toastr.s("Тест успешно удален!");
                    this.items = [];
                    this.getElemTests();
                    //return (snapshot.data())  
                }).catch(err=>{console.log(err)});
                break;
              case 'GRAMMAR_VIDEOS': 
              firebase.bVideosGrammarCollection.doc(item.id).delete().then(() => {
                  this.dialog2 = false;
                  this.$toastr.s("Тест успешно удален!");
                  this.items = [];
                  this.getElemTests();
                  //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
              break;
              case 'LISTENING': 
              firebase.bVideosCollection.doc(item.id).delete().then(() => {
                  this.dialog2 = false;
                  this.$toastr.s("Тест успешно удален!");
                  this.items = [];
                  this.getElemTests();
                  //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
              break;
              case 'READING': 
              firebase.bReadingCollection.doc(item.id).delete().then(() => {
                  this.dialog2 = false;
                  this.$toastr.s("Тест успешно удален!");
                  this.items = [];
                  this.getElemTests();
                  //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
              break;
              case 'VOCABULARY': 
              firebase.bVocabularyCollection.doc(item.id).delete().then(() => {
                  this.dialog2 = false;
                  this.$toastr.s("Тест успешно удален!");
                  this.items = [];
                  this.getElemTests();
                  //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
              break;
                
            }
          }
          else if(item.level === 'ELEMENTARY'){
            switch (item.part) {
              case 'GRAMMAR': 
                firebase.eGrammarCollection.doc(item.id).delete().then(() => {
                    this.dialog2 = false;
                    this.$toastr.s("Тест успешно удален!");
                    this.items = [];
                    this.getElemTests();
                    //return (snapshot.data())  
                }).catch(err=>{console.log(err)});
                break;
              case 'GRAMMAR_VIDEOS': 
              firebase.eVideosGrammarCollection.doc(item.id).delete().then(() => {
                  this.dialog2 = false;
                  this.$toastr.s("Тест успешно удален!");
                  this.items = [];
                  this.getElemTests();
                  //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
              break;
              case 'LISTENING': 
              firebase.eVideosCollection.doc(item.id).delete().then(() => {
                  this.dialog2 = false;
                  this.$toastr.s("Тест успешно удален!");
                  this.items = [];
                  this.getElemTests();
                  //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
              break;
              case 'READING': 
              firebase.eReadingCollection.doc(item.id).delete().then(() => {
                  this.dialog2 = false;
                  this.$toastr.s("Тест успешно удален!");
                  this.items = [];
                  this.getElemTests();
                  //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
              break;
              case 'VOCABULARY': 
              firebase.eVocabularyCollection.doc(item.id).delete().then(() => {
                  this.dialog2 = false;
                  this.$toastr.s("Тест успешно удален!");
                  this.items = [];
                  this.getElemTests();
                  //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
              break;
                
            }
          }
          else if(item.level === 'PRE-INTERMEDIATE'){
            switch (item.part) {
              case 'GRAMMAR': 
                firebase.pGrammarCollection.doc(item.id).delete().then(() => {
                    this.dialog2 = false;
                    this.$toastr.s("Тест успешно удален!");
                    this.items = [];
                    this.getElemTests();
                    //return (snapshot.data())  
                }).catch(err=>{console.log(err)});
                break;
              case 'GRAMMAR_VIDEOS': 
              firebase.pVideosGrammarCollection.doc(item.id).delete().then(() => {
                  this.dialog2 = false;
                  this.$toastr.s("Тест успешно удален!");
                  this.items = [];
                  this.getElemTests();
                  //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
              break;
              case 'LISTENING': 
              firebase.pVideosCollection.doc(item.id).delete().then(() => {
                  this.dialog2 = false;
                  this.$toastr.s("Тест успешно удален!");
                  this.items = [];
                  this.getElemTests();
                  //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
              break;
              case 'READING': 
              firebase.pReadingCollection.doc(item.id).delete().then(() => {
                  this.dialog2 = false;
                  this.$toastr.s("Тест успешно удален!");
                  this.items = [];
                  this.getElemTests();
                  //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
              break;
              case 'VOCABULARY': 
              firebase.pVocabularyCollection.doc(item.id).delete().then(() => {
                  this.dialog2 = false;
                  this.$toastr.s("Тест успешно удален!");
                  this.items = [];
                  this.getElemTests();
                  //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
              break;
                
            }
          }
        },
        editItem(item) {
          console.log(item);
          this.item = item;
          this.editDialog = true;
          //this.$router.push({ name: 'TestEdit', params: { testId: item.id } });
        },
        async editTest(){
          this.test.questions = this.questionArr;
          if(this.level.name === 'BEGINNER'){
            console.log('BEGINNER!')
            if(this.level.name === 'BEGINNER' && this.part.name === 'GRAMMAR' && this.type.name === 'TEST'){
              this.test.part = "GRAMMAR";
              this.test.level = "BEGINNER";
              await firebase.bGrammarCollection.add(this.test).then((snapshot) => {
                firebase.bGrammarCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.bGrammarCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Тест успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'BEGINNER' && this.part.name === 'READING') {
              this.test.part = "READING";
              this.test.level = "BEGINNER";
              await firebase.bReadingCollection.add(this.test).then((snapshot) => {
                firebase.bReadingCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.bReadingCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Тест успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'BEGINNER' && this.part.name === 'GRAMMAR_VIDEOS') {
              this.test.part = "GRAMMAR_VIDEOS";
              this.test.level = "BEGINNER";
              await firebase.bVideosGrammarCollection.add(this.test).then((snapshot) => {
                firebase.bVideosGrammarCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.bVideosGrammarCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Видео успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'BEGINNER' && this.part.name === 'LISTENING') {
              this.test.part = "LISTENING";
              this.test.level = "BEGINNER";
              await firebase.bVideosCollection.add(this.test).then((snapshot) => {
                firebase.bVideosCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.bVideosCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Видео успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'BEGINNER' && this.part.name === 'VOCABULARY') {
              this.test.part = "VOCABULARY";
              this.test.level = "BEGINNER";
              this.test.words = this.wordArr;
              await firebase.bVocabularyCollection.add(this.test).then((snapshot) => {
                firebase.bVocabularyCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.bVocabularyCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Тест успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
          }
          else if(this.level.name === 'ELEMENTARY') {
            console.log('ELEMENTARY!')
            if(this.level.name === 'ELEMENTARY' && this.part.name === 'GRAMMAR'){
              this.test.part = "GRAMMAR";
              this.test.level = "ELEMENTARY";
              await firebase.eGrammarCollection.add(this.test).then((snapshot) => {
                firebase.eGrammarCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.eGrammarCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Тест успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'ELEMENTARY' && this.part.name === 'READING') {
              this.test.part = "READING";
              this.test.level = "ELEMENTARY";
              await firebase.eReadingCollection.add(this.test).then((snapshot) => {
                firebase.eReadingCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.eReadingCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Тест успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'ELEMENTARY' && this.part.name === 'GRAMMAR_VIDEOS') {
              this.test.part = "GRAMMAR_VIDEOS";
              this.test.level = "ELEMENTARY";
              await firebase.eVideosGrammarCollection.add(this.test).then((snapshot) => {
                firebase.eVideosGrammarCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.eVideosGrammarCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Видео успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'ELEMENTARY' && this.part.name === 'LISTENING') {
              this.test.part = "LISTENING";
              this.test.level = "ELEMENTARY";
              await firebase.eVideosCollection.add(this.test).then((snapshot) => {
                firebase.eVideosCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.eVideosCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Видео успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'ELEMENTARY' && this.part.name === 'VOCABULARY') {
              this.test.part = "VOCABULARY";
              this.test.level = "ELEMENTARY";
              this.test.words = this.wordArr;
              await firebase.eVocabularyCollection.add(this.test).then((snapshot) => {
                firebase.eVocabularyCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.eVocabularyCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Тест успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
          }
          else if(this.level.name === 'PRE-INTERMEDIATE') {
            console.log('PRE-INTERMEDIATE');
            if(this.level.name === 'PRE-INTERMEDIATE' && this.part.name === 'GRAMMAR'){
              this.test.part = "GRAMMAR";
              this.test.level = "PRE-INTERMEDIATE";
              await firebase.pGrammarCollection.add(this.test).then((snapshot) => {
                firebase.pGrammarCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.pGrammarCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Тест успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'PRE-INTERMEDIATE' && this.part.name === 'READING') {
              this.test.part = "READING";
              this.test.level = "PRE-INTERMEDIATE";
              await firebase.pReadingCollection.add(this.test).then((snapshot) => {
                firebase.pReadingCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.pReadingCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Тест успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'PRE-INTERMEDIATE' && this.part.name === 'GRAMMAR_VIDEOS') {
              this.test.part = "GRAMMAR_VIDEOS";
              this.test.level = "PRE-INTERMEDIATE";
              await firebase.pVideosGrammarCollection.add(this.test).then((snapshot) => {
                firebase.pVideosGrammarCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.pVideosGrammarCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Видео успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'PRE-INTERMEDIATE' && this.part.name === 'LISTENING') {
              this.test.part = "LISTENING";
              this.test.level = "PRE-INTERMEDIATE";
              await firebase.pVideosCollection.add(this.test).then((snapshot) => {
                firebase.pVideosCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.pVideosCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Видео успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
            else if(this.level.name === 'PRE-INTERMEDIATE' && this.part.name === 'VOCABULARY') {
              this.test.part = "VOCABULARY";
              this.test.level = "PRE-INTERMEDIATE";
              this.test.words = this.wordArr;
              await firebase.pVocabularyCollection.add(this.test).then((snapshot) => {
                firebase.pVocabularyCollection.doc(snapshot.id).get().then((snapshot) => {
                  const data = snapshot.data();
                  firebase.pVocabularyCollection.doc(snapshot.id).set({
                    ...data,
                    id:snapshot.id
                  }).then(() => {
                    this.test = {};
                  })
                })
                this.dialog = false;
                this.items = [];
                this.getElemTests();
                this.$toastr.s("Тест успешно добавлен!");
                //return (snapshot.data())  
              }).catch(err=>{console.log(err)});
            }
          }
        },
        addResponses(question){
            const responseObj = {
                title: '',
                isCorrect: false
            };
            question.responses.push(responseObj);
        },
        addQuestions(){
            this.num += 1;
            const questionObj = {
                text: '',
                title: this.num,
                responses: []
            };
            this.questionArr.push(questionObj);
        },
        addWords(){
            this.num += 1;
            const wordObj = {
                transcript: '',
                title: this.num,
                translate:'',
                example:''
            };
            this.wordArr.push(wordObj);
        },
        addFilling(){
            this.num += 1;
            const fillObj = {
                quest: '',
                title: this.num,
                answer:'',
            };
            this.fillArr.push(fillObj);
        },
        onLevelChange() {
          if(this.level.name === 'BEGINNER'){
            this.level.beginnered = true;
          }
          else if(this.level.name === 'ELEMENTARY'){
            this.level.elementared = true;
          }
          else {
            this.level.preintermediated = true;
          }
        },
        onLChange() {
          if(this.l.name === 'BEGINNER'){
            this.getBeginnerTests();
          }
          else if(this.l.name === 'ELEMENTARY'){
            this.getElemTests();
          }
          else {
            this.getPreTests();
          }
        },
        onTChange(t) {
          if(this.l.name === 'BEGINNER'){
            this.items = [];
            this.getBeginnerTests();
            this.arrays = this.items;
            this.items = this.arrays.filter(i => i.part === t.name);
            
          }
          else if(this.l.name === 'ELEMENTARY'){
            this.items = [];
            this.getElemTests();
            this.items = this.items.filter(i => i.part === t.name);
            console.log(this.items);
          }
          else {
            this.items = [];
            this.getPreTests();
            this.items = this.items.filter(i => i.part === t.name);
          }
        },
        onPartChange() {
          if(this.part.name === 'GRAMMAR'){
            this.part.grammared = true;
          }
          else if(this.part.name === 'VOCABULARY'){
            this.part.vocabulared = true;
          }
          else if(this.part.name === 'READING'){
            this.part.readered = true;
          }
          else {
            this.part.videoded = true;
          }
        },
        async onChange(e) {
            const file = e;
            const storageRef = firebase.storage.ref();
            const fileRef = storageRef.child('Uploads/'+ file.name)
            await fileRef.put(file);
            this.fileUrl = await fileRef.getDownloadURL();
        },
    },
}
</script>
<style lang="scss" scoped>
.hospitals{
  &__btn{
    color:#FFFFFF;
  }
  &__addbtn{
    color:#FFFFFF;
  }
}
.theme--light.v-btn {
    color: rgba(255, 255, 255, 0.87);
}
</style>